<template>
    <div class="content">
      
        <el-row class="bgW">
            <el-col :span="22">
            <!--检索条件-->
            <el-form class="search" :inline="true" size="mini" :model="search">
                <el-form-item label="反馈时间">
                    <el-date-picker
                        v-model="search.create_time_start"
                        type="date"
                        class="w130"
                        placeholder="开始日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <el-date-picker
                        v-model="search.create_time_end"
                        type="date"
                        class="w130"
                        placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-form-item>
                
                <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
                    <el-select
                        v-model="search.shop_id"
                        clearable
                        remote
                        filterable
                        :remote-method="remoteMethod"
                        placeholder="请输入"
                        class="w120"
                    >
                    <el-option
                        v-for="item in options.shop_id"
                        :key="item.shopId"
                        :label="item.shopName"
                        :value="item.shopId"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item label="反馈类型">
                    <el-select v-model="search.feed_type" clearable class="w120">
                        <el-option
                        v-for="item in options.feed_type"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        />
                    </el-select>
                </el-form-item>
    
                
            </el-form>
            </el-col>
            <el-col :span="2" style="text-align: right;">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
            </el-col>
        </el-row>
  
        <div class="accountCon">
            <div class="btnBox">
            <el-button
                type="primary"
                class="mb10"
                size="mini"
                v-if="!userInfo.shop_id"
                @click="exportData('export')"
            >导出数据</el-button>
            </div>
            <!--列表-->
            <el-row>
                <el-col :span="24">
                    <el-table
                        :data="list"
                        size="small"
                        border
                        :cell-style="$style.cellStyle"
                        :header-cell-style="$style.rowClass"
                        @selection-change="handleSelectionChange"
                    >
                        <ElTableColumn label="姓名" prop="customer_name" width="150"  fixed/>
                        <ElTableColumn label="手机" width="100" prop="tel" />
                        <ElTableColumn label="门店/门诊" prop="shop_name" width="100" />
                        <ElTableColumn label="反馈类型" width="80" prop="type" />
                        <ElTableColumn label="反馈内容" width="1180" prop="feed_back_value" />
                        <ElTableColumn label="提交时间" width="140" prop="create_time" />
                        <!-- <ElTableColumn label="操作" fixed="right">
                            <template slot-scope="{ row }">
                            <span class="theme" @click="tapBtn('客户详情',row.id)">查 看</span>
                            </template>
                        </ElTableColumn> -->
                    </el-table>

                    <pagination
                        class="page tc mt10"
                        :total="page.total"
                        :page.sync="page.page"
                        :limit.sync="page.limit"
                        @pagination="getList"
                    />
                </el-col>
            </el-row>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import pagination from "@/components/Pagination";
import {
    clientListLS,
    ssettingList,
    statusSetting,
    getGradeList,
    clientExport
} from "@/api/client";
import { getShopList } from "@/api/shop";
import { feedbackList } from "@/api/sys";
  
class Search {
    create_time_start = "";
    create_time_end = "";
    shop_id = "";
    feed_type = "";
}

class Options {
    
    shop_id = [];
    feed_type = [];
    
}

class Page {
    total = 0;
    page = 1;
    limit = 10;
}
export default {
    name: "AlarmClient",
    components: {
        pagination
    },
    data() {
        return {
            search: new Search(),
            options: new Options(),
            page: new Page(),
            list: [],
            idList: "",
        };
    },
    filters: {
        statusFilter(val) {
            return ["启用", "禁用"][+val];
        }
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    mounted() {
        this.getList();
        this.getOptions();
    },
    methods: {
        getOptions() {
            ssettingList({ page: 1, limit: 100000 }).then(res => {
                let that = this;
                res.list.forEach(function(item) {
                    if (item.category_id == 14) {
                        that.options.feed_type.push(item);
                    } 
                });
            });
        },
        // 获取列表
        getList(option) {
            
            if (option === "search") this.page = new Page();
            feedbackList({ ...this.search, ...this.page, is_export:0 }).then(
                
                res => {
                    // console.log(res);
                    // return;
                    this.list = res.data.list;
                    this.page.total = res.data.dataCount;
                }
            );
        },
  
        // 导出数据
        exportData(option) {
            

            if (option === "export") this.page = new Page();
            feedbackList({ ...this.search, ...this.page, is_export:1 }).then(
                res => {
                    // console.log(res);
                    window.open(res.data.url);
                }
            );
        },
  
        // 搜索门店
        remoteMethod(e) {
            getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
                this.options.shop_id = res.list;
            });
        },

        // 点击按钮详情
        tapBtn(routerName, id) {
            const query = { id: id, activeName: "first" };
            // this.$router.push({ name: routerName, query });
            let route = this.$router.resolve({ name: routerName, query });
            window.open(route.href, '_blank');
        },

        // 提示
        hint(text) {
            this.$confirm(`${text},是否确定？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.getExport();
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消"
                });
            });
        },

        // 导出
        getExport() {
            clientExport({ id: this.idList, type: 5 }).then(res => {
                window.open(res.data.url);
            });
        },

        // 改变表格选中状态
        handleSelectionChange(val) {
            this.idList = val.map(i => {
                return i.id;
            });
        }
    }
};
</script>
  
<style lang="scss" scoped>
.mainColor {
    color: #2dc0a3;
}
.content {
    height: calc(100% - 56px);
    overflow-y: auto;
}
.bgW {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
}
  
.el-form-item {
    margin: 5px 10px 3px 0;
}
.accountCon {
    background: #fff;
    padding: 0px 10px 80px;
    .btnBox {
        display: flex;
    }
}
.describe{
    padding-left: 10px;
    .describeText{
        color: #666;
    }
    .dataMsg {
        line-height: 30px;
        span {
            margin-left: 0px;
            font-weight: bold;
        }
    }
}
  
</style>
  